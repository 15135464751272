import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import {gql, useMutation, useQuery} from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import cn from "classnames"
import {
    getCurrencyFormatter,
    getDayMonthYearFormatter,
    getIntegerFormatter, getNumberFormatter, getPercentSafeFormatter,
    getSafeFormatter,
} from "../../../i18n"
import { ProductDetailExpander } from "../../util/ProductDetailExpander"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import {showError, showInfo} from "../../../redux/actions/NotificationActions";
import {useDispatch} from "react-redux"
import {Pagination} from "../../util/table/Pagination"
import {Link} from "react-router-dom"

const GET_PRODUCTS = gql`
    query($pageNum: Int!){
        marketingLists(
            limit: 1
            orderBy: [CreatedAtDesc]
        ){
            page {
                id
                name
                createdAt
            }
        }
        timeCriticalProductList(
            pageNum: $pageNum
            limit: 20
        ){
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                marketingType
                validUntil
                validFrom
                salePrice
                salesPercent
                fromAmount
                aep
                product {
                    id
                    pzn
                    name
                    unit
                    supplierName
                }
            }
        }
    }
`

const DOWNLOAD_PRODUCTS = gql`
    mutation download{
        downloadTimeCriticalProducts
    }
`

export const TimeCriticalProductList = () => {
    const [expanderProduct, setExpanderProduct] = useState(null)

    const [loadProducts, setLoadProducts] = useState(false)
    const [pageNum, setPage] = useState(1)

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation(["common", "table", "page_names"])

    const dayMonthYearFormat = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )

    const integerFormat = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    const percentFormat = getPercentSafeFormatter(
        getNumberFormatter(i18n.language),
    )

    const currencyFormat = getSafeFormatter(
        getCurrencyFormatter(i18n.language),
    )

    const { data, loading } = useQuery(GET_PRODUCTS, {
        skip: !loadProducts,
        variables: {
            pageNum,
        }
    })

    const [download] = useMutation(DOWNLOAD_PRODUCTS)

    return (
        <div>
            <Banner text={t("page_names:time_critical_products")}/>
            {loadProducts && <div className={dropDownStyle.container}>
                <div className={tableStyle.buttonBar}>
                {data?.marketingLists?.page?.length ?
                <Link to={`/marketing/${data?.marketingLists?.page[0]?.id}`}>
                    {t("table:from_upload") + ": " +  data?.marketingLists?.page[0]?.name + ` [${dayMonthYearFormat.format(new Date(data?.marketingLists?.page[0]?.createdAt))}]`}
                </Link>: <span/>
                }
                </div>
                <div className={tableStyle.buttonBar}>
                    <button
                        className={buttonStyle.button}
                        disabled={!data?.timeCriticalProductList?.page?.length}
                        onClick={() => download().then(() =>
                                    dispatch(
                                        showInfo("common:export_triggered"),
                                    ),
                                )
                                .catch((e) => dispatch(showError(e.message)))}
                    >
                        {c(t("table:export_products"))}
                    </button>
                </div>
            </div>}
            {!loading && !data?.timeCriticalProductList?.page?.length && loadProducts ? (
                <Flash text={t("table:no_results")} type={WARNING}/>
            ) : null}

            {! loadProducts && <div className={cn(tableStyle.buttonBar, tableStyle.centerButtonBar)}>
                <button
                    className={cn(buttonStyle.button, buttonStyle.bigButton)}
                    onClick={() => {
                        setLoadProducts(true)
                    }}
                >
                    {c(t("table:load_products"))}
                </button>
            </div>}

            {loadProducts && <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "2fr 1fr 2fr repeat(7, 1fr)",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:producer")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pzn")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:article_name")}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:package_unit")}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:aep")}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:from_amount")}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:sale_price")}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:sales_percent")}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:marketing_type")}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:sale_time_frame")}
                />

                        {(loading) && !data?.list && (
                            <TableContentLoader columns={10} />
                        )}
                        {data?.timeCriticalProductList?.page.map((s, i) => (
                            <Fragment key={i}>
                                <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                                    {s?.product?.supplierName}
                                </p>
                                <p
                                    className={tableStyle.cell}
                                    onClick={() =>
                                        setExpanderProduct({
                                            ...s?.product,
                                            row: i,
                                        })
                                    }
                                >
                                    {s?.product?.pzn}
                                </p>
                                <p
                                    className={tableStyle.cell}
                                    onClick={() =>
                                        setExpanderProduct({
                                            ...s?.product,
                                            row: i,
                                        })
                                    }
                                >
                                    {s?.product?.name}
                                </p>
                                <p className={tableStyle.cell}>
                                    {s?.product?.unit}
                                </p>
                                <p className={tableStyle.cell}>
                                    {currencyFormat.format(s?.aep)}
                                </p>
                                <p className={tableStyle.cell}>
                                    {integerFormat.format(s?.fromAmount)}
                                </p>
                                <p className={tableStyle.cell}>
                                    {currencyFormat.format(s?.salePrice)}
                                </p>
                                <p className={tableStyle.cell}>
                                    {percentFormat.format(s?.salesPercent)}
                                </p>
                                <p className={tableStyle.cell}>
                                    {s?.marketingType}
                                </p>
                                <p
                                    className={cn(
                                        tableStyle.cell, tableStyle.lastCell
                                    )}
                                >
                                    {dayMonthYearFormat.format(
                                        new Date(s?.validFrom),
                                    )}
                                    {" - "}
                                    {dayMonthYearFormat.format(
                                        new Date(s?.validUntil),
                                    )}
                                </p>

                                <ProductDetailExpander
                                    isShown={
                                        expanderProduct && expanderProduct === s?.id
                                    }
                                    close={() => setExpanderProduct(null)}
                                    product={s}
                                />
                            </Fragment>
                        ))}
            </div>}
            {loadProducts && <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.timeCriticalProductList}
                loading={loading && !data?.timeCriticalProductList}
            />}
        </div>
    )
}
